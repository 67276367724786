import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
// import qs from 'qs'

// import { getToken } from '@/util/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // 正式
  baseURL: 'https://zjiva.terabits.cn:9091',
  // 本地
  // baseURL: 'http://192.168.1.61:9000',
  // 测试
  // baseURL: 'https://zjapi.terabits.cn',
  // baseURL: 'https://ivatest.terabits.cn:9093',
  // baseURL: 'https://csiva.terabits.cn:9091',
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  // if (getToken() && !isToken) {
  //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  // get请求映射params参数
  // console.log('config :>> ', config);
  if (config.method === 'get' && config.params) {
    // console.log('config.params :>> ', config.params);
    const obj = config.params
    for (var key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key]
      }
    }
    config.params = {
      ...obj
    }
  }
  // } else if (config.method === 'post' || config.method === 'put') {
  //   let data = qs.parse(config.data)
  //   config.data = qs.stringify({
  //     ...data
  //   })
  // }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 401) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      store.dispatch('FedLogOut').then(() => {
        location.href = '/login';
      })
    }).catch(() => { });
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code === 40163) {
    Notification.error({
      title: 'code失效，请重新尝试'
    })
  }
  else if (code !== 200) {
    Notification.error({
      title: '返回错误，请重新尝试'
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
